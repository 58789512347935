import type { BlockTypes } from '@etf1-interne/tf1info_types_news'

import { useContext, useId } from 'react'
import { theme } from '../../../styles'
import { Headline } from '../../atoms/Headline'
import { ArticleItem } from '../../atoms/ArticleItem'
import { ArticleListItem } from '../../atoms/ArticleListItem/ArticleListItem'
import { TagCommanderContext } from '../../../contexts/tagcommander'

export function ArticleList({
  elementList,
  title,
  isEnriched,
}: BlockTypes['article-list']['data']): JSX.Element {
  const titleId = useId()
  const { hit } = useContext(TagCommanderContext)
  const ArticleItemComponent = isEnriched ? ArticleListItem : ArticleItem

  function handleOnClick(isSponsored: boolean) {
    hit(
      {
        screen_clickableElementName: `bloc_liste-article${isSponsored ? '_sponso' : ''}`,
      },
      { isClickEvent: true },
    )
  }

  return (
    <>
      <div data-testid="ArticleList" className="ArticleList">
        {title && <Headline id={titleId} htmlText={title} />}
        <ul aria-labelledby={title ? titleId : undefined}>
          {elementList.map((element, index) => (
            <li key={index}>
              <ArticleItemComponent {...element} onClick={() => handleOnClick(!!element.sponsor)} />
            </li>
          ))}
        </ul>
      </div>

      <style jsx>{`
        .ArticleList {
          margin: 0px ${theme.block.marginLR}px 10px;
        }

        .ArticleList :global(.Headline) {
          margin-bottom: 30px;
        }

        .ArticleList ul {
          list-style: none;
          padding: 0;
        }

        .ArticleList :global(article) {
          position: relative;
          margin: 0 0 32px;
        }

        .ArticleList :global(.ArticleItem),
        .ArticleList :global(.ArticleListItem) {
          padding-bottom: 20px;
          border-bottom: 1px solid ${theme.cssVars.lightGray};
        }
      `}</style>
    </>
  )
}
