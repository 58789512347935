import type { ITopic } from '../../atoms/Topic'
import { useContext } from 'react'
import { Topic } from '../../atoms/Topic'
import { Headline } from '../../atoms/Headline'
import { mixins, theme } from '../../../styles'
import { TagCommanderContext } from '../../../contexts/tagcommander'

export interface ITopicList {
  elementList: Array<ITopic>
  title?: string
}

export function TopicList({ elementList, title }: ITopicList): JSX.Element {
  const { hit } = useContext(TagCommanderContext)

  const handleOnClick = () => {
    hit(
      {
        screen_clickableElementName: `bloc_topic-list`,
      },
      { isClickEvent: true },
    )
  }

  return (
    <>
      <div className="TopicList">
        {title && <Headline htmlText={title} />}
        <ul className="TopicList__Content flex flex-column">
          {elementList?.map((topic) => (
            <li key={topic.link} className="TopicList__Item">
              <Topic {...topic} onClick={handleOnClick} />
            </li>
          ))}
        </ul>
      </div>

      <style jsx>{`
        .TopicList {
          list-style: none;
          margin: 30px 4px 50px;
          padding: 0;
        }

        .TopicList__Content {
          list-style: none;
          padding: 0;
          gap: 4px;
        }

        .TopicList :global(.Headline) {
          margin-bottom: 30px;
        }

        @media ${mixins.mediaQuery.onlyTabletPaysage} {
          .TopicList {
            margin: 30px ${theme.block.marginLR}px 50px;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .TopicList__Content {
            flex-direction: row;
            align-items: stretch;
          }

          .TopicList__Item :global(a) {
            height: 100%;
            margin: 0px;
            display: flex;
          }

          .TopicList__Item :global(.Topic) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 30px;
            gap: 12px;
          }

          .TopicList__Item :global(.Topic) :global(.Link) {
            margin: 0;
          }

          .TopicList__Item :global(.Topic__Arrow) {
            margin: 0;
          }

          .TopicList__Item :global(.Topic__Container) {
            justify-content: flex-start;
          }

          .TopicList__Item {
            flex: 1;
          }

          .TopicList__Item:last-child {
            border-right: 0;
          }
        }
      `}</style>
    </>
  )
}
