import type { BlockTypes } from '@etf1-interne/tf1info_types_news'

import { useState } from 'react'
import { mixins, theme } from '../../../styles'
import { PictureElementList } from '../PictureElementList'
import { Time } from '../Time'
import { Link } from '../Link'
import { Icon } from '../Icon'
import { SVGIcon } from '../SVGIcon'
import { PreviewVideo } from '../PreviewVideo'
import { CategoryLabel } from '../CategoryLabel'

export type IArticleListItem = BlockTypes['article-list']['data']['elementList'][number] & {
  placeholderTheme?: 'dark' | 'light'
  iconColor?: string
  onClick?: () => void
}

export function ArticleListItem({
  category,
  date,
  hasMultiLive,
  icon,
  iconColor,
  link,
  pictures,
  placeholderTheme = 'light',
  title,
  description,
  sponsor,
  onClick,
  previewVideoUrl,
}: IArticleListItem): JSX.Element {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const hasPicture = !!pictures
  return (
    <>
      <article
        className="ArticleListItem"
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <h2 className="ArticleListItem__Title">
          {hasMultiLive && (
            <SVGIcon
              preserveAspectRatio="xMidYMid meet"
              name="direct"
              className="ArticleListItem__DirectIcon"
              data-testid="ArticleListItem__DirectIcon"
            />
          )}
          <a className="ArticleListItem__Link" href={link} onClick={onClick}>
            {title}
          </a>
        </h2>
        <CategoryLabel
          title={category?.title}
          color={category?.color}
          className="ArticleListItem__Category"
        />
        {hasPicture ? (
          <div className="ArticleListItem__Picture">
            {icon && (
              <>
                <SVGIcon
                  name="play-circle"
                  className="ArticleListItem__Play"
                  size="32px"
                  secondaryColor={theme.cssVars.white}
                />
                <SVGIcon
                  name="play-circle-hover"
                  className="ArticleListItem__Play__hover"
                  size="37px"
                  secondaryColor={theme.cssVars.white}
                />
                <div className="ArticleListItem__Play__hover__Dash" />
              </>
            )}
            {previewVideoUrl ? (
              <PreviewVideo
                url={previewVideoUrl}
                pictures={pictures}
                withIcon={false}
                isActive={isMouseOver}
              />
            ) : (
              <PictureElementList
                {...pictures}
                alt={pictures?.caption || title}
                placeholderTheme={placeholderTheme}
                aria-hidden
              />
            )}
          </div>
        ) : null}

        {description ? <p className="ArticleListItem__Description">{description}</p> : null}

        <footer className="ArticleListItem__Footer flex items-center">
          {icon && !hasPicture ? (
            <Icon name="play" primaryColor={iconColor ?? theme.cssVars.deepBlue} size="16px" />
          ) : null}
          {date && (
            <time dateTime={date} className="ArticleListItem__Date">
              <Time publishedAt={date} />
            </time>
          )}
          {sponsor?.pictures ? (
            <div className="ArticleListItem__Sponsor flex items-center justify-center">
              {sponsor.title ? (
                <span className="ArticleListItem__Sponsor__Label">{sponsor.title}</span>
              ) : null}
              <Link url={sponsor.link} onClick={onClick}>
                <PictureElementList
                  alt={sponsor.pictures.caption}
                  elementList={sponsor.pictures.elementList}
                  lazyload={sponsor.pictures.lazyload}
                  placeholderTheme="light"
                />
              </Link>
            </div>
          ) : null}
        </footer>
      </article>

      <style jsx>{`
        .ArticleListItem {
          display: grid;
          background-color: ${sponsor ? theme.cssVars.lightBlue : 'transparent'};
          padding: ${sponsor ? '15px' : '0'};


        .ArticleListItem:hover .ArticleListItem__Title {
          color: ${theme.cssVars.focusBlue};
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleListItem {
            padding: ${sponsor ? '46px' : '0'};
          }
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleListItem__Title {
            grid-column: ${hasPicture ? '2/3' : '1/3'};
          }
        }

        .ArticleListItem__Picture {
          padding-top: ${previewVideoUrl ? '0' : '56.25%'}; // ratio of 16/9
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleListItem__Picture {
            padding: 0;
          }
        }
      `}</style>
      <style jsx>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes pulse {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }

        @keyframes spin {
          100% {
            transform: rotateZ(360deg);
          }
        }

        .ArticleListItem {
          display: grid;
          gap: 12px;
          grid-template-areas:
            'category'
            'picture'
            'title'
            'description'
            'footer';
        }

        .ArticleListItem :global(.ArticleListItem__Category) {
          grid-area: category;
          display: block;
          font-size: 13px;
        }

        .ArticleListItem__Picture :global(.ArticleListItem__Play) {
          position: absolute;
          bottom: 14px;
          left: 14px;
          z-index: 3;
        }

        .ArticleListItem__Picture :global(.ArticleListItem__Play__hover) {
          position: absolute;
          bottom: 12px;
          left: 11.2px;
          z-index: 3;
        }

        .ArticleListItem :global(.ArticleListItem__Play__hover__Dash) {
          position: absolute;
          bottom: 15px;
          left: 15px;
          height: 30px;
          width: 30px;
          background-color: transparent;
          border-radius: 50%;
          background-color: ${theme.cssVars.deepBlue};
          z-index: 2;
        }

        .ArticleListItem :global(.ArticleListItem__Play__hover),
        .ArticleListItem :global(.ArticleListItem__Play__hover__Dash) {
          visibility: hidden;
          opacity: 0;
          animation: pulse 1s opacity;
        }

        .ArticleListItem :global(.ArticleListItem__Play__hover__Dash) {
          transition: all 2s ease-in-out;
        }

        .ArticleListItem:hover :global(.ArticleListItem__Play__hover),
        .ArticleListItem:hover :global(.ArticleListItem__Play__hover__Dash) {
          visibility: visible;
          opacity: 1;
        }

        .ArticleListItem:hover :global(.ArticleListItem__Play__hover) {
          animation: fadeIn 350ms;
        }

        .ArticleListItem:hover :global(.ArticleListItem__Play) {
          visibility: hidden;
          opacity: 0;
        }

        .ArticleListItem:hover :global(.ArticleListItem__Play__hover__Dash:before) {
          position: absolute;
          content: '';
          height: 35px;
          width: 35px;
          border: 1px dashed ${theme.cssVars.white};
          border-width: 1px;
          top: -3.6px;
          left: -3px;
          border-radius: inherit;
          animation: spin 10s linear infinite;
          z-index: 2;
        }

        .ArticleListItem__Link {
          text-decoration: none;
        }

        .ArticleListItem__Link:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
        }

        .ArticleListItem__Picture {
          grid-area: picture;
          position: relative;
          width: 100%;

          border-radius: 4px;
          overflow: hidden;
        }

        .ArticleListItem__Picture :global(img) {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
        }

        .ArticleListItem:hover .ArticleListItem__Title {
          color: ${theme.cssVars.focusBlue};
        }

        .ArticleListItem__Title {
          grid-area: title;
          color: ${theme.cssVars.deepBlue};
          margin: 0;
          font-size: 20px;
          font-weight: 800;
          display: inline;
        }

        .ArticleListItem__Title :global(.ArticleListItem__DirectIcon) {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-bottom: -2px;
          margin-right: 2px;
        }

        .ArticleListItem__Description {
          grid-area: description;
          color: ${theme.cssVars.deepBlue};
          margin: -5px 0 0 0;
          font-weight: 400;
          font-size: 17px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .ArticleListItem__Footer {
          grid-area: footer;
          margin-top: -3px;
        }

        .ArticleListItem__Footer :global(.Icon) {
          padding-top: 2px;
          margin-right: 6px;
        }

        .ArticleListItem__Date {
          color: ${theme.cssVars.midBlue};
          padding-top: 4px;
          ${mixins.fonts.base.xsmall};
        }

        .ArticleListItem__Sponsor {
          float: right;
          margin-left: auto;
        }

        .ArticleListItem__Sponsor__Label {
          font-weight: 600;
          font-size: 13px;
          line-height: 145%;
          color: ${theme.cssVars.deepBlue};
        }

        .ArticleListItem__Sponsor :global(img) {
          height: 32px;
        }

        .ArticleListItem__Sponsor :global(a) {
          height: 32px;
          margin: 0;
          margin-left: 10px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleListItem {
            display: grid;
            grid-template-columns: 215px 1fr;
            grid-template-rows: repeat(5, auto);
            grid-gap: 12px;
          }

          .ArticleListItem__Category {
            grid-column: 1/3;
            grid-row: 1;
          }

          .ArticleListItem__Picture {
            grid-column: 1 / 3;
            grid-row: 2;
            width: 215px;
            height: 121px;
          }

          .ArticleListItem__Title {
            font-size: 25px;
            grid-row: 2;
            line-height: 31px;
            margin-top: -3px;
          }

          .ArticleListItem__Title :global(.ArticleListItem__DirectIcon) {
            width: 24px;
            height: 24px;
          }

          .ArticleListItem__Description {
            grid-column: 1/3;
            grid-row: 3;
            margin: 0;
          }

          .ArticleListItem__Footer {
            grid-column: 1/3;
            grid-row: 4;
            margin-top: -5px;
          }
        }
      `}</style>
    </>
  )
}
