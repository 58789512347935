import { theme } from '../../../styles'
import { Button } from '../../atoms/Button'
import { SVGIcon } from '../../atoms/SVGIcon'

export interface ITagList {
  elementList: {
    id: string
    link: string
    title: string
  }[]
  wordings: {
    title: string
    linkLabel: string
    link: string
  }
}

export function TagList({ elementList, wordings }: ITagList): JSX.Element {
  return (
    <>
      <div className="TagList flex justify-center items-center">
        {wordings.title ? (
          <h1 className="TagList__Title" dangerouslySetInnerHTML={{ __html: wordings.title }} />
        ) : null}
        <ul className="TagList__List flex">
          {elementList.map((tag) => (
            <li key={tag.id} className="TagList__Item">
              <a className="TagList__Item_Link flex" href={tag.link}>
                {tag.title}
              </a>
            </li>
          ))}
        </ul>
        {wordings.linkLabel && wordings.link ? (
          <Button
            textColor={'var(--color-white)'}
            backgroundColor={'var(--color-deepBlue)'}
            href={wordings.link}
          >
            {wordings.linkLabel}

            <SVGIcon name="arrow" primaryColor={theme.cssVars.white} />
          </Button>
        ) : null}
      </div>

      <style jsx>{`
        .TagList {
          flex-wrap: wrap;
          padding: 20px ${theme.block.marginBottom}px;
        }

        .TagList__Title {
          width: 100%;
          text-align: left;
          color: var(--color-deepBlue);
          font-size: 38px;
          font-weight: 100;
        }

        .TagList__Title :global(strong) {
          font-weight: 800;
        }

        .TagList__List {
          flex-wrap: wrap;
          list-style: none;
          padding: 0;
          margin-bottom: ${theme.block.marginBottom}px;
        }

        .TagList__Item {
          width: 100%;
        }

        .TagList__Item_Link {
          color: var(--color-deepBlue);
          font-weight: 700;
          font-size: 18px;
          line-height: 144%;
          padding: 16px 0;
        }

        .TagList :global(svg) {
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
        }

        .TagList :global(.Button) {
          align-self: center;
          max-width: 342px;
        }

        .TagList :global(.Button svg) {
          margin-left: 16px;
        }
      `}</style>
    </>
  )
}
